import {
  Box,
  Button,
  Container,
  Grid,
  GridCol,
  Group,
  Image,
  Paper,
  rem,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import Ebru from './focus-ebru.jpg';
import HusniHat from './focus-husni-hat.jpg';
import Islamic from './focus-islamic.jpg';
import Miniature from './focus-miniature.jpg';
import Tezhib from './focus-tazhib.jpg';

export default function BlogFocus(props) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <Box id={'islamic-art-focus'}>
      <Container py={{ base: 50, md: 100 }} {...props}>
        <Stack align="center" m={'0 auto'}>
          <Title lh={1.2} fw={500} fz={rem(48)} lts={rem(-3)} c={'gfdark.9'} maw={{ base: 350, sm: '100%' }} ta={'center'}>
            Islamic Art Focus
          </Title>
          <Text c={'gfdark.6'} fz={18} fw={'lighter'} ta={'center'} maw={600}>
            At Goldframer, we carefully select categories of Islamic art to showcase the breadth and depth of this timeless
            artistic tradition.
          </Text>

          <Grid justify="space-between" align="stretch" mt={{ base: 'xl', md: 90 }} gutter={'xl'}>
            <GridCol span={{ base: 12, md: 8 }}>
              <Paper shadow={0} radius={0} withBorder p={0}>
                <Image w={'100%'} mah={260} src={HusniHat} hiddenFrom="md"></Image>
                <Group gap={0} wrap={'nowrap'}>
                  <Box p={'xl'}>
                    <a rel="follow" href="https://blog.goldframer.com/what-is-husn-u-hat-or-islamic-calligraphy/">
                      <Text fz={rem(32)} c={'gfdark.9'}>
                        Hüsn-i Hat - Islamic Caligraphy
                      </Text>
                    </a>
                    <Text fz={rem(14)} c={'gfdark.4'} mt={'md'} className="tw-balance">
                      Islamic calligraphy, known as "Hüsn-i Hat", is the art of beautiful and decorative writing. In Islamic art,
                      calligraphy holds significant importance as it is often used to convey religious texts, verses from the
                      Quran, or other meaningful phrases.
                    </Text>
                    <a rel="follow" href="https://blog.goldframer.com/what-is-husn-u-hat-or-islamic-calligraphy/">
                      <Button size="md" mt={'md'}>
                        MORE
                      </Button>
                    </a>
                  </Box>
                  <a rel="follow" href="https://blog.goldframer.com/what-is-husn-u-hat-or-islamic-calligraphy/">
                    <Image w={230} h={360} src={HusniHat} visibleFrom="md"></Image>
                  </a>
                </Group>
              </Paper>
            </GridCol>
            <GridCol span={{ base: 12, md: 4 }}>
              <Paper shadow={0} radius={0} withBorder p={0} h={'100%'}>
                <Image w={'100%'} mah={260} src={Miniature} hiddenFrom="md"></Image>
                <Stack
                  bg={{ base: 'white', md: 'gfdark.9' }}
                  p={'xl'}
                  align={'flex-start'}
                  justify={'center'}
                  h={{ base: 'auto', md: '100%' }}
                >
                  <a
                    rel="follow"
                    href="https://blog.goldframer.com/tiny-masterpieces-the-evolution-and-influence-of-islamic-miniature-painting/"
                  >
                    <Text fz={rem(32)} c={{ base: 'gfdark.9', md: 'white' }}>
                      Miniature
                    </Text>
                  </a>
                  <Text fz={rem(14)} c={'gfdark.4'} className="tw-balance">
                    Miniature art features detailed illustrations in manuscripts with precision and vibrant colors. Despite their
                    small size, miniature paintings are known for their intricate detail and exquisite craftsmanship.
                  </Text>
                  <a
                    rel="follow"
                    href="https://blog.goldframer.com/tiny-masterpieces-the-evolution-and-influence-of-islamic-miniature-painting/"
                  >
                    <Button size="md" c={isMobile ? 'gfdark.9' : 'white'}>
                      MORE
                    </Button>
                  </a>
                </Stack>
              </Paper>
            </GridCol>
            <GridCol span={{ base: 12, md: 8 }}>
              <Paper shadow={0} radius={0} withBorder p={0}>
                <Image w={'100%'} mah={260} src={Tezhib} hiddenFrom="md"></Image>
                <Group gap={0} wrap={'nowrap'}>
                  <Box p={'xl'}>
                    <a rel="follow" href="https://blog.goldframer.com/what-is-tazheb/">
                      <Text fz={rem(32)} c={'gfdark.9'}>
                        Tazhib
                      </Text>
                    </a>
                    <Text fz={rem(14)} c={'gfdark.4'} mt={'md'} className="tw-balance">
                      Tazhib, delicate art of illuminating manuscripts with gold, adding intricate designs, and enhancing the
                      aesthetic appeal of the text. Illumination, or ornamentation, involves adding intricate designs, borders,
                      and embellishments to the text, enhancing its aesthetic appeal and making it more visually striking.
                    </Text>
                    <a rel="follow" href="https://blog.goldframer.com/what-is-tazheb/">
                      <Button size="md" mt={'md'}>
                        MORE
                      </Button>
                    </a>
                  </Box>
                  <a rel="follow" href="https://blog.goldframer.com/what-is-tazheb/">
                    <Image w={230} h={360} src={Tezhib} visibleFrom="md"></Image>
                  </a>
                </Group>
              </Paper>
            </GridCol>
            <GridCol span={{ base: 12, md: 4 }}>
              <Paper shadow={0} radius={0} withBorder p={0} h={'100%'}>
                <Image w={'100%'} mah={260} src={Ebru} hiddenFrom="md"></Image>
                <Stack
                  bg={{ base: 'white', md: 'gfdark.9' }}
                  p={'xl'}
                  align={'flex-start'}
                  justify={'center'}
                  h={{ base: 'auto', md: '100%' }}
                >
                  <a rel="follow" href="https://blog.goldframer.com/what-is-ebru-art-of-marbling/">
                    <Text fz={rem(32)} c={{ base: 'gfdark.9', md: 'white' }}>
                      Ebru
                    </Text>
                  </a>
                  <Text fz={rem(14)} c={'gfdark.4'} className="tw-balance">
                    Ebru is the art of paper marbling, which involves creating colorful patterns on water and then transferring
                    them to paper or fabric. Ebru is valued for its mesmerizing patterns and the element of chance involved in
                    creating each piece.
                  </Text>
                  <a rel="follow" href="https://blog.goldframer.com/what-is-ebru-art-of-marbling/">
                    <Button size="md" c={isMobile ? 'gfdark.9' : 'white'}>
                      MORE
                    </Button>
                  </a>
                </Stack>
              </Paper>
            </GridCol>
            <GridCol span={12}>
              <Paper shadow={0} radius={0} withBorder p={0}>
                <Image w={'100%'} mah={260} src={Islamic} hiddenFrom="md"></Image>
                <Group gap={0} wrap={'nowrap'}>
                  <Box p={'xl'}>
                    <a
                      rel="follow"
                      href="https://blog.goldframer.com/islamic-world-paintings-a-celebration-of-islamic-art-and-culture/"
                    >
                      <Text fz={rem(32)} c={'gfdark.9'}>
                        Islamic World Paintings
                      </Text>
                    </a>
                    <Text fz={rem(14)} c={'gfdark.4'} mt={'md'} className="tw-balance">
                      Islamic World Paintings are artistic works that reflect the rich cultural and artistic heritage of the
                      Muslim culture. They merge traditional and modern elements, showcasing the aesthetic and spiritual values of
                      Islam while incorporating artistic techniques and styles from various regions.
                    </Text>
                    <a
                      rel="follow"
                      href="https://blog.goldframer.com/islamic-world-paintings-a-celebration-of-islamic-art-and-culture/"
                    >
                      <Button size="md" mt={'md'}>
                        MORE
                      </Button>
                    </a>
                  </Box>
                  <Image miw={345} h={360} src={Islamic} visibleFrom="md"></Image>
                </Group>
              </Paper>
            </GridCol>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
